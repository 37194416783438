*{
  box-sizing: border-box;
	user-select: none;
}

body {
  margin: 0;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #ffff00;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'AlarmClock';
  src: local('AlarmClock'), url(./fonts/AlarmClock.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'LLPIXEL3';
  src: local('LLPIXEL3'), url(./fonts/LLPIXEL3.ttf) format('truetype');
}

@font-face {
  font-family: 'ArialBlack';
  src: local('ArialBlack'), url(./fonts/ArialBlack.ttf) format('truetype');
}